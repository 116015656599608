import api from 'src/utils/api';
import 'src/assets/geetest/index'
declare const initGeetest: any
export default function getChallenge(dom: HTMLCollectionOf<Element>, cb:any) {
    const handler = (captchaObj: any) => {
        captchaObj.appendTo(dom); //将验证按钮插入到宿主页面中captcha元素内
        captchaObj.onReady(function(){
        }).onSuccess(function(){
            var result = captchaObj.getValidate();
            api.post('/cloudH5/validate', {
                challenge: result.geetest_challenge,
                validate: result.geetest_validate,
                seccode: result.geetest_seccode,
            }).then(
                (res) => {
                    const data = res.data;
                    if(data.status === 'success') {
                        cb(data);
                    } else {
                        throw new Error();
                    }
                }
            ).catch(
                () => {
                    captchaObj.reset();
                }
            );
        }
        ).onError(function(){
          captchaObj.reset();
        })
    }
    api.post('/cloudH5/captchaImage').then(
        (res) => {
            const data = res.data;
            initGeetest({
                gt: data.gt,
                challenge: data.challenge,
                offline: !data.success,
                product: "popup",
                https: true,
                width: '100%',
                api_server:
									window.location.host.includes("icp.pingan.com")
                        ? "captcha-api.pingan.com"
                        : "captcha-api-stg.pingan.com:10530",//需根据不同环境修改对应的域名
            }, handler);
        }
    ).catch(
        (e) => {
            console.log(e);
        }
    )
}


